<script>

import store from "@/store";

import {ref, watch} from "vue";
import {useStore} from "vuex";

export default {
  name: 'Header',
  setup() {

    const langs = ref([{name: 'O\'zbek', code: 'Uz'},
      {name: 'Русский', code: 'Ru'}, {name: 'English', code: 'En'}])

    return {
      langs,
    }
  },
  methods: {
    switchLang(lang) {
      store.commit('setLocale', lang)
      // $i18n is reactively
      this.$i18n.locale = lang.toLowerCase();
    },
  },
}
</script>

<template>

  <!-- Header Area -->
  <header class="main_header_arae">
    <!-- Top Bar -->
    <div class="topbar-area">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6">
            <ul class="topbar-list">
              <li><a href="http://t.me/hiro_travel"><i class="fab fa-telegram"></i></a></li>
              <li><a href="https://instagram.com/hiro_uzb?igshid=MWI4MTIyMDE="><i class="fab fa-instagram"></i></a></li>
              <li><a href="https://www.youtube.com/@hiro_uzb"><i class="fab fa-youtube"></i></a></li>
              <li><a href="tel: +998 97 592 1444"><span>+998 91 161 1444</span></a></li>
            </ul>
          </div>
          <div class="col-lg-6 col-md-6">
            <ul class="topbar-others-options">
              <li>
                <div class="dropdown language-option">
                  <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="false">
                    Til
                    <span class="lang-name"></span>
                  </button>
                  <div class="dropdown-menu language-dropdown-menu">
                    <button class="dropdown-item"
                            v-for="lang in langs"
                            @click="switchLang(lang.code)"
                            :key="lang.code">
                      {{ lang.name }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Navbar Bar -->
    <div class="navbar-area">
      <div class="main-responsive-nav">
        <div class="container">
          <div class="main-responsive-menu">
            <div class="logo">
              <a href="/">
                <img src="assets/img/logo.png" alt="logo" style="max-height: 50px">
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="main-navbar">
        <div class="container">
          <nav class="navbar navbar-expand-md navbar-light">
            <a class="navbar-brand" href="">
              <img src="assets/img/logo.png" alt="logo" style="max-height: 50px">
            </a>
            <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a href="#" class="nav-link active">
                    {{ $t('homePage')}}
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#choose_destinations_area" class="nav-link">
                    {{ $t('tours')}}
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#about_two_area" class="nav-link">
                    {{ $t('aboutUs')}}
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#top_service_andtour" class="nav-link">
                    {{ $t('services')}}
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#our_partners" class="nav-link">
                    {{ $t('partners')}}
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#tour_booking_submission" class="nav-link">
                    {{ $t('booking')}}
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#footer_area" class="nav-link">
                    {{ $t('contactUs')}}
                  </a>
                </li>
              </ul>
              <div class="others-options d-flex align-items-center">
                <div class="option-item">
                  <a href="#" class="search-box">
                    <i class="bi bi-search"></i></a>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div class="others-option-for-responsive">
        <div class="container">
          <div class="dot-menu">
            <div class="inner">
              <div class="circle circle-one"></div>
              <div class="circle circle-two"></div>
              <div class="circle circle-three"></div>
            </div>
          </div>
          <div class="container">
            <div class="option-inner">
              <div class="others-options d-flex align-items-center">
                <div class="option-item">
                  <a href="#" class="search-box"><i class="fas fa-search"></i></a>
                </div>
                <div class="option-item">
                  <a href="contact.html" class="btn  btn_navber">Get free quote</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <!-- search -->
  <div class="search-overlay">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="search-overlay-layer"></div>
        <div class="search-overlay-layer"></div>
        <div class="search-overlay-layer"></div>
        <div class="search-overlay-close">
          <span class="search-overlay-close-line"></span>
          <span class="search-overlay-close-line"></span>
        </div>
        <div class="search-overlay-form">
          <form>
            <input type="text" class="input-search" placeholder="Search here...">
            <button type="button"><i class="fas fa-search"></i></button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>