<script setup>

import {MockData} from "@/service/mock-data";

const services = new MockData().topServices;

</script>

<template>
  <!-- Top Service And Tour Area-->
  <section id="top_service_andtour" class="section_padding_top">
    <div class="container">
      <!-- Section Heading -->
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="section_heading_center">
            <h2>{{ $t('home.services')}}</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12" v-for="service in services" :key="service">
          <div class="top_service_boxed">
            <img :src="service.image" alt="icon">
            <h3>{{ $t('home.service'+service.index+'.title')}}</h3>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<style scoped lang="scss">

</style>