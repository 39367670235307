<script setup>

import {ref} from "vue";
import axios from "axios";
import {useStore} from "vuex";

const tours = ref([])
const state = useStore().state
const data = ref({
  name: '',
  phone: '+998',
  message: '',
  tour: null,
})
let dataSaving = ref(false)

async function getTours() {
  await axios.get('tours/')
      .then(response => {
        tours.value = response.data
        data.tour = tours.value[0].id
      })
      .catch(error => {
        console.log(error)
      })
}

getTours()

function saveData() {

  dataSaving.value = true

  // check data
  if (data.value.name === '') {
    alert('Ismingizni kiriting')
    dataSaving.value = false
    return
  }
  if (data.value.phone === '') {
    alert('Telefon raqamingizni kiriting')
    dataSaving.value = false
    return
  }
  if (data.value.tour === null) {
    alert('Tur tanlang')
    dataSaving.value = false
    return
  }

  axios.post('order/', data.value)
      .then(response => {
        dataSaving.value = false
        console.log(response.data)
        alert('Buyurtma qabul qilindi')
        location.href = response.data
      })
      .catch(error => {
        dataSaving.value = false
        console.log(error)
      })
}

</script>

<template>
  <section id="tour_booking_submission"
           class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 ">
          <div class="tou_booking_form_Wrapper">
            <div class="booking_tour_form">
              <h3 class="heading_theme">{{ $t('booking') }}</h3>
              <div class="tour_booking_form_box">
                <form action="!#" id="tour_bookking_form_item">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <input type="text" class="form-control bg_input"
                                v-model="data.name"
                               :placeholder="$t('name')">
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <input type="text" class="form-control bg_input"
                                v-model="data.phone"
                               :placeholder="$t('phone')">
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <input type="text" class="form-control bg_input"
                                v-model="data.message"
                               :placeholder="$t('questionsAndOffers')">
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <select class="form-control form-select bg_input"
                                v-model="data.tour">
                          <option v-for="t in tours"
                                  :key="t"
                                  :value="t.id">
                            {{ t['title' + state.locale] }} | ${{ t['price']}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="booking_tour_form">
              <h3 class="heading_theme">{{ $t('paymentMethods')}}</h3>
              <div class="tour_booking_form_box">
                <div class="booking_payment_boxed">
                  <form action="!#" id="payment_checked">
                    <div class="form-check">
                      <input class="form-check-input" type="radio"
                             selected
                             name="flexRadioDefault"
                             id="flexRadioDefault1" value="red">
                      <label class="form-check-label" for="flexRadioDefault1">
                        Humo / Uzcard (Click)
                      </label>
                    </div>
                    <div class="payment_filed_wrapper">
                      <div class="payment_card payment_toggle red">
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="form-group">
                              <input type="text" class="form-control bg_input"
                                     placeholder="Card number">
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <input type="text" class="form-control bg_input"
                                     placeholder="Cardholder name">
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <input type="text" class="form-control bg_input"
                                     placeholder="Date of expiry">
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <input type="text" class="form-control bg_input"
                                     placeholder="Security code">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="paypal_payment payment_toggle green">
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="form-group">
                              <input type="text" class="form-control bg_input"
                                     placeholder="Email Address">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="payoneer_payment payment_toggle black">
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="form-group">
                              <input type="text" class="form-control bg_input"
                                     placeholder="Email Address">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="booking_tour_form_submit">
              <button class="btn btn_theme btn_md"
                      @click="saveData()"
                      :class="dataSaving ? 'disabled':''">
                {{ $t('bookNow') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">

</style>