<script setup>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import {MockData} from "@/service/mock-data";
import {onMounted, ref} from "vue";
import axios from "axios";
import {useStore} from "vuex";

const sliders = new MockData().homeSlider;

const apiSliders = ref([])
const store = useStore()
const lang = store.state.locale

async function getSlider() {
  await axios.get('/slider/').then(res => {
    apiSliders.value = res.data
  }).catch(err => {
    console.log(err)
  })
}

onMounted(() => {
  getSlider()
})

</script>

<template>
  <carousel :items-to-show="1" class="w-100" :wrapAround="true">
    <slide v-for="i in apiSliders" :key="i.index" class="w-100">
      <div class="banner_two_slider_item fadeInUp" data-wow-duration="2s"
           :style="'background-image: url('+ i.image.replaceAll('https://localhost:8000', axios.defaults.baseURL) +');'">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="banner_two_text">
                <h1 class="slider-sttle whitespace">{{ i['location' + store.state.locale] }}</h1>
                <h2 class="slider-pararp">{{ i['subtitle' + store.state.locale] }}</h2>
                <h4 class="slider-pararp"> {{ i['title' + store.state.locale] }}</h4>
                <div class="home_two_button btn-animation">
                  <a href="#footer_area" class="btn btn_theme_white btn_md">{{ $t('home.slide'+ 1 +'.button')}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </slide>

    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>
  <!-- Banner Area -->
  <section id="home_two_banner">
    <div class="home_two_banner_slider_wrapper owl-carousel owl-theme">



    </div>
  </section>
</template>

<style scoped lang="scss">
.carousel__slide{
  display: block;
}
.whitespace{
  white-space: nowrap;
}
</style>
