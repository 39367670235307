import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { createI18n } from 'vue-i18n'
import ru from './service/ru.json'
import uz from './service/uz.json'
import en from './service/en.json'
import VueAxios from "vue-axios";
import axios from "axios";
import './axios'

const i18n = createI18n({
    locale: 'uz',
    // something vue-i18n options here ...
    messages: {
        en: en,
        ru: ru,
        uz: uz
    }
})

createApp(App)
    .use(store)
    .use(i18n)
    .use(router)
    .use(VueAxios, axios)
    .mount('#app')
