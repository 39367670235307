import { createStore } from 'vuex'

export default createStore({
  state: {
    locale: 'Uz',
  },
  getters: {
    locale: state => state.locale,
  },
  mutations: {
    setLocale(state, locale) {
        state.locale = locale;
    }
  },
  actions: {
    locale({ commit }, locale) {
        commit('setLocale', locale);
    }
  },
  modules: {
  }
})
