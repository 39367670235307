<script setup>

</script>

<template>
  <!-- Footer  -->
  <footer id="footer_area">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
          <div class="footer_heading_area">
            <h5>{{ $t('needHelp') }}</h5>
          </div>
          <div class="footer_first_area">
            <div class="footer_inquery_area">
              <h5>{{ $t('callNow') }}</h5>
              <h3> <a href="tel:+998 97 592 1444">+998 91 161 14 44</a></h3>
            </div>
            <div class="footer_inquery_area">
              <h5>{{ $t('social') }}</h5>
              <ul class="soical_icon_footer">
                <li><a href="http://t.me/hiro_travel"><i class="fab fa-telegram"></i></a></li>
                <li><a href="https://instagram.com/hiro_uzb?igshid=MWI4MTIyMDE="><i class="fab fa-instagram"></i></a></li>
                <li><a href="https://www.youtube.com/@hiro_uzb"><i class="fab fa-youtube"></i></a></li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
  </footer>
  <div class="copyright_area">
    <div class="container">
      <div class="row align-items-center">
        <div class="co-lg-6 col-md-6 col-sm-12 col-12">
          <div class="copyright_left">
            <p>Copyright © 2023 All Rights Reserved</p>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="go-top">
    <i class="fas fa-chevron-up"></i>
    <i class="fas fa-chevron-up"></i>
  </div>
</template>

<style scoped lang="scss">

</style>