<script setup>

import {Carousel, Slide} from "vue3-carousel";
import {ref} from "vue";
import axios from "axios";

const breakpoints = ref({
  // 700px and up
  700: {
    itemsToShow: 3.5,
    snapAlign: 'center',
  },
  // 1024 and up
  1024: {
    itemsToShow: 5,
    snapAlign: 'center',
  }
})


const data = ref([])

async function getData() {
  await axios.get('/partners/').then(res => {
    data.value = res.data
  }).catch(err => {
    console.log(err)
  })
}

getData()

</script>

<template>
  <section id="our_partners" class="section_padding">
    <div class="container">
      <!-- Section Heading -->
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="section_heading_center">
            <h2>{{ $t('partners') }}</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <carousel class="w-100" :items-to-show="2.5" :wrap-around="true" :breakpoints="breakpoints">
            <slide v-for="i in data" :key="i">
              <div class="partner_slider_area">
                <div class="partner_logo">
                  <a href="#!"><img :src="i.image.replaceAll('https://localhost:8000', axios.defaults.baseURL)" alt="logo"></a>
                </div>
              </div>
            </slide>
          </carousel>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">

</style>
