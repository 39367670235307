<script setup>

</script>

<template>
  <!-- About Area -->
  <section id="about_two_area" class="section_padding_top">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-12 col-sm-12 col-12">
          <div class="about_two_left">
            <div class="about_two_left_top">
              <h5>{{ $t('about') }}</h5>
              <h2>{{ $t('aboutTitle') }}</h2>
              <p>
                {{ $t('aboutText') }}
              </p>
            </div>
            <div class="about_two_left_middel">
              <div class="about_two_item">
                <div class="about_two_item_icon">
                  <img src="assets/img/icon/about-1.png" alt="icon">
                </div>
                <div class="about_two_item_text">
                  <h3>{{ $t('guide') }}</h3>
                  <p>{{ $t('guideText') }}</p>
                </div>
              </div>
              <div class="about_two_item">
                <div class="about_two_item_icon">
                  <img src="assets/img/icon/about-2.png" alt="icon">
                </div>
                <div class="about_two_item_text">
                  <h3>{{ $t('tourPackages') }}</h3>
                  <p>{{ $t('tourPackagesText') }}</p>
                </div>
              </div>
              <div class="about_two_item">
                <div class="about_two_item_icon">
                  <img src="assets/img/icon/about-3.png" alt="icon">
                </div>
                <div class="about_two_item_text">
                  <h3>{{ $t('places') }}</h3>
                  <p>{{ $t('placesText') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="about_two_left_img">
            <img src="assets/img/common/about_two.png" alt="img">
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<style scoped lang="scss">

</style>