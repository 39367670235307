export class MockData {

    partners = [
        {
            index: 1,
            image: 'assets/img/partner/1.png',
        },
        {
            index: 2,
            image: 'assets/img/partner/2.png',
        },
        {
            index: 3,
            image: 'assets/img/partner/3.png',
        }
    ];

    homeSlider = [
        {
            index: 1,
            image: 'assets/img/banner/kaba.jpg',
        },
        {
            index: 2,
            image: 'assets/img/banner/tour.jpg',
        },
        {
            index: 3,
            image: 'assets/img/banner/places.jpg',
        },
    ];

    topServices = [
        {
            index: 1,
            image: 'assets/img/icon/service-1.png',
        },
        {
            index: 2,
            image: 'assets/img/icon/service-2.png',
        },
        {
            index: 3,
            image: 'assets/img/icon/service-3.png',
        }
    ];

    tours = [
        {
            index: 1,
            title: "Umra, Saudiya Arabistoni",
            description: "Umra — islomda hajjdan boshqa bir necha vaqtda bajariladigan ibodat. Umra — Saudiya Arabistoni mintaqasida joylashgan. Umra shahri 2005-yilda tashkil etilgan. Umra shahri 2005-yilda viloyat statusini olishdi. Umra shahri 2005-yilda viloyat markaz shahri boʻldi. Umra shahri 2005-yilda viloyat markaz shahri boʻlganidan soʻng, shahar hududi 1,500 km² ga oʻzgardi. Umra shahri 2005-yilda 1,500 nafar aholiga ega boʻldi. Umra shahri 2005-yilda 1,500 nafar aholiga ega boʻldi.",
            image: 'assets/img/tour/kaba.jpg',
            price: '$1250',
            rating: 4.5,
            transfer: 'Samalyot',
            duration: '10',
            country: 'Saudiya Arabistoni',
        },
        {
            index: 2,
            title: "Buxoro, O'zbekiston",
            description: "Buxoro Oʻzbekistondagi shahar. Buxoro viloyatining markaz shahri. Oʻzbekistonning eng qadimgi shaharlari bilan bir qator. Buxoro shahri 1993-yil 30-iyulda 2500 yilligi nishonlanadi. Buxoro shahri 1997-yil 12-sentabrda Oʻzbekiston Respublikasi Prezidenti Shavkat Mirziyoyevning farmoni bilan «Oʻzbekiston Respublikasining eng qadimgi shaharlari» deb atalgan. Buxoro shahri 1999-yil 30-avgustda Oʻzbekiston Respublikasi Prezidenti Islam Karimovning farmoni bilan «Oʻzbekiston Respublikasining eng qadimgi shaharlari» deb atalgan.",
            image: 'assets/img/tour/buxoro.png',
            price: '$145',
            rating: 4.5,
            transfer: 'Avtobus',
            duration: '5',
            country: 'O\'zbekiston',
        },
        {
            index: 3,
            title: "Sharm al-SHayx,Misr",
            description: "Sharm al-SHayx — Misrda turizm markazi. Sharm al-SHayx — Sharm al-SHayx viloyatining markaz shahri. Sharm al-SHayx shahri 1982-yilda tashkil etilgan. Sharm al-SHayx shahri 1996-yilda viloyat statusini olishdi. Sharm al-SHayx shahri 2000-yilda viloyat markaz shahri boʻldi. Sharm al-SHayx shahri 2005-yilda viloyat markaz shahri boʻlganidan soʻng, shahar hududi 53,000 km² ga oʻzgardi. Sharm al-SHayx shahri 2005-yilda 35,000 nafar aholiga ega boʻldi. Sharm al-SHayx shahri 2005-yilda 35,000 nafar aholiga ega boʻldi.",
            image: 'assets/img/tour/sharm.png',
            price: '$750',
            rating: 4.5,
            transfer: 'Samalyot',
            duration: '10',
            country: 'Misr',
        },
        {
            index: 4,
            title: "Istanbul, Turkiya",
            description: "Istanbul — Turkiyada joylashgan shahar. Istanbul — Istanbul viloyatining markaz shahri. Istanbul shahri 1923-yilda tashkil etilgan. Istanbul shahri 1923-yilda viloyat statusini olishdi. Istanbul shahri 1923-yilda viloyat markaz shahri boʻldi. Istanbul shahri 2005-yilda viloyat markaz shahri boʻlganidan soʻng, shahar hududi 5,461 km² ga oʻzgardi. Istanbul shahri 2005-yilda 10,018,735 nafar aholiga ega boʻldi. Istanbul shahri 2005-yilda 10,018,735 nafar aholiga ega boʻldi.",
            image: 'assets/img/tour/istanbul.png',
            price: '$675',
            rating: 4.5,
            transfer: 'Samalyot',
            duration: '10',
            country: 'Turkiya',
        },
        {
            index: 5,
            title: "Paris, Fransiya",
            description: "Paris — Fransiyaning poytaxti. Paris — Île-de-France mintaqasining markaz shahri. Paris shahri 508-yilda tashkil etilgan. Paris shahri 508-yilda viloyat statusini olishdi. Paris shahri 508-yilda viloyat markaz shahri boʻldi. Paris shahri 2005-yilda viloyat markaz shahri boʻlganidan soʻng, shahar hududi 105,400 km² ga oʻzgardi. Paris shahri 2005-yilda 2,125,246 nafar aholiga ega boʻldi. Paris shahri 2005-yilda 2,125,246 nafar aholiga ega boʻldi.",
            image: 'assets/img/tour/paris.png',
            price: '$840',
            rating: 4.5,
            transfer: 'Samalyot',
            duration: '10',
            country: 'Fransiya',
        },
        {
            index: 6,
            title: "Samarqand, O'zbekiston",
            shortInfo: "Oʻzbekistondagi masjid va maqbaralari bilan mashhur shahar.",
            description: "Samarqand Oʻzbekistondagi masjid va maqbaralari bilan mashhur shahar. Buyuk ipak yoʻli Samarqand orqali oʻtgan – Xitoyni Oʻrta yer dengizi mamlakatlari bilan bogʻlagan savdo yoʻli. Eng muhim diqqatga sazovor joylar: 15-17-asrlarga oid uchta madrasaga ega Registon maydoni, mayolika bilan bezatilgan va Gur-Amir maqbarasi - Temuriylar imperiyasining asoschisi Temur (Temurlan)ning ulug'vor maqbarasi.",
            image: 'assets/img/tour/samarqand.png',
            price: '$150',
            rating: 4.5,
            transfer: 'Avtobus',
            duration: '5',
            country: 'O\'zbekiston',
        }
    ]
}