<script setup>

</script>

<template>
  <!-- Partner Area -->
  <section id="partner_area" class="section_padding_top">
    <div class="container">
      <div class="partner_area_bg">
        <div class="row">
          <div class="col-lg-6">
            <div class="partner_area_left">
              <h5>{{ $t('yourPartnerText') }}</h5>
              <h2>{{ $t('yourPartnerTitle') }}</h2>
              <p>{{ $t('yourPartnerDescription') }}</p>

              <div class="local_counter_area">
                <div class="local_counter_box">
                  <h2>12K</h2>
                  <h5>{{ $t('localGuides')}}</h5>
                </div>
                <div class="local_counter_box">
                  <h2>750+</h2>
                  <h5>{{ $t('destinations') }}</h5>
                </div>
                <div class="local_counter_box">
                  <h2>100%</h2>
                  <h5>{{ $t('happiness') }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>

</template>

<style scoped lang="scss">

</style>