<template>
  <!-- preloader Area -->
  <div class="preloader">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>

  <SliderComponent/>

  <DestinationsComponent/>

  <AboutComponent/>

  <PartnerComponent/>

  <ServicesComponent/>

  <PartnerArea/>

  <PhotoGallery/>

<!--  <DocumentComponent/>-->

  <!-- Partner Area -->
  <section id="hometwo_clinet_area" class="section_padding">
    <div class="container">
      <div class="client_two_area_bg">
        <div class="row align-items-center">
          <div class="col-lg-5">
            <div class="two_client_area_left">
              <h2>{{ $t('ss23') }}</h2>
              <div class="sm_call_area">
                <img src="assets/img/icon/sm-call.png" alt="icon">
                <div class="sm_call_number">
                  <h5>{{ $t('callNow') }}</h5>
                  <h2><a href="tel:+998 91 161 14 44">+998 91 161 14 44</a></h2>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="home_client_wrpper">
              <h2>{{ $t('clientSays') }}</h2>
              <div class="home-two-quote">
                <img src="assets/img/icon/quote.png" alt="img">
                <h3>Awesome service</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Cta Area -->
  <section id="cta_area">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-7">
          <div class="cta_left">
            <div class="cta_icon">
              <img src="assets/img/common/email.png" alt="icon">
            </div>
            <div class="cta_content">
              <h4>{{ $t('newsLetter') }}</h4>
              <h2>{{ $t('subscribe') }}</h2>
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="cat_form">
            <form id="cta_form_wrappper">
              <div class="input-group"><input type="text" class="form-control"
                                              v-model="subscribeEmail"
                                              placeholder="Enter your mail address">
                <button class="btn btn_theme btn_md"
                        @click="kola()"
                        type="button">Obuna bo'lish
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>

  <Booking/>


</template>

<script setup>
import '@/assets/custom.js'
// @ is an alias to /src
import SliderComponent from "@/components/SliderComponent.vue";
import ServicesComponent from "@/components/ServicesComponent.vue";
import AboutComponent from "@/components/AboutComponent.vue";
import PartnerComponent from "@/components/PartnerComponent.vue";
import DestinationsComponent from "@/components/DestinationsComponent.vue";
import {ref} from "vue";
import {Bot} from "@/service/bot";
import {MockData} from "@/service/mock-data";
import PartnerArea from "@/components/PartnerArea.vue";
import PhotoGallery from "@/components/PhotoGallery.vue";
import DocumentComponent from "@/components/DocumentComponent.vue";
import Booking from "@/components/Booking.vue";

let subscribeEmail = ref('')

const bot = new Bot()

const data = new MockData()

function kola() {
  bot.send('subscribe ' + subscribeEmail.value)
  subscribeEmail.value = ''
}

</script>
