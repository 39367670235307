<script setup>

import {MockData} from "@/service/mock-data";
import {ref} from "vue";
import {useStore} from "vuex";
import axios from "axios";

const data = new MockData().tours
const apiTours = ref([])
let store = useStore()
let lang = useStore().state.locale

async function getTours() {
  await axios.get('/tours/').then(res => {
    apiTours.value = res.data
  }).catch(err => {
    console.log(err)
  })
}

getTours()

</script>

<template>
  <!-- Choose your destinations Area-->
  <section id="choose_destinations_area" class="section_padding_top">
    <div class="container">
      <!-- Section Heading -->
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="section_heading_center">
            <h2>{{ $t('chooseDestinations') }}</h2>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12" v-for="tour in apiTours">
          <div class="choose_desti_wrapper">
            <div class="choose_des_inner_wrap">
              <div class="choose_boxed_inner align-items-center" style="max-height: 300px">
                <img :src="tour.image.replaceAll('https://localhost:8000', axios.defaults.baseURL)" alt="img" >
                <div class="choose_img_text">
                  <h2>{{ tour['title' + store.state.locale] }} | ${{ tour.price }}</h2>
                  <h3>{{tour.duration }} {{ $t('days')}}</h3>
                </div>
              </div>
              <div class="flep_choose_box">
                <div class="flep_choose_box_inner">
                  <div class="rating_outof">
                    <i class="fas fa-star" v-for="star in tour.rating" :key="star"></i>
                  </div>
                  <h2>{{ tour['title' + store.state.locale] }} | {{ tour.price }}</h2>
                  <h3>{{tour.duration }} {{ $t('days')}}</h3>
                  <p></p>
                  <a href="#">{{ $t('tourDetails') }}</a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<style scoped lang="scss">

</style>
