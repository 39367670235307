<script setup>
import {Carousel, Slide} from "vue3-carousel";
import {ref} from "vue";
import axios from "axios";

let currentSlide = ref(0)

function slideTo(val) {
  currentSlide.value = val
}

const slides = ref([])

async function getData() {
  await axios.get('/gallery/').then(res => {
    slides.value = res.data
    currentSlide.value = 0
  }).catch(err => {
    console.log(err)
  })
}

getData()

</script>

<template>

  <section id="our_partners" class="section_padding">
    <div class="container">
      <!-- Section Heading -->
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="section_heading_center">
            <h2>{{ $t('gallery') }}</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <carousel id="gallery"
                    :items-to-show="1"
                    :wrap-around="true"
                    v-model="currentSlide">
            <slide v-for="slide in slides" :key="slide">
              <div class="carousel__item">
                <img :src="slide.image.replaceAll('https://localhost:8000', axios.defaults.baseURL)" alt="logo">
              </div>
            </slide>
          </carousel>

          <carousel
              id="thumbnails"
              :items-to-show="4"
              :wrap-around="true"
              v-model="currentSlide"
              ref="carousel"
          >
            <slide v-for="(slide, index) in slides" :key="slide">
              <div class="carousel__item" @click="slideTo(index)">
                <img :src="slide.image.replaceAll('https://localhost:8000', axios.defaults.baseURL)" alt="logo">
              </div>
            </slide>
          </carousel>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">

</style>
